define(function() {
  /**
   * This config file is shared between prequal and prod envs
   */
  function getEnv() {
    let env = "";
    let hostname = window.location.hostname;
    if (hostname.indexOf("-prequal") !== -1) {
      env += "-prequal";
    }
    return env;
  }
  /**
   * Global Configuration Parameters - Singleton
   */
  const GCONFIG = (function() {
    // private data
    const _useMwaLogin = true;
    const _loginUrl = _useMwaLogin ? `https://login.mathworks.com/embedded-login/signin.html?cid=mldo&locale=en_US&uri=https%3A%2F%2Fdrive${getEnv()}.mathworks.com%2F`
          : `https://login.mathworks.com/embedded-login/v2/login.html?locale=en_US`;
    const _logoutUrl = "https://login.mathworks.com/embedded-login/logout.html?uri=";
    const _gdsUrl = "https://gds.mathworks.com/gds/service/v1";
    const _html5PushState = true;
    const _daoClassName = 'GDSDAO';  // classname of DAO implementation to use
    const _routerHistoryBase = '/';
    const _deleteEnabled = true;
    const _useAPS = true;
    const _apsBrokerUrl =  "apsbroker.mss-mathworks.com\/websocket\/agent";
    const _apsAuthUrl = "https://authnz.mathworks.com/messages/json";
    const _apsLoginUrl = "https://login.mathworks.com/authenticationws/service/v4/login";
    const _apsAuthProxyUrl = ""; // "https://gds.mathworks.com/gds/service/v1/apslogin";
    const _apsStorageTopic = "/gds/user/storageChange";
    const _apsResourceTopic = "/gds/content/resourceChange";
    const _apsResourceAttributesTopic = "/gds/content/resourceAttributesChange";
    const _apsInvitationTopic = "/gds/content/invitation";
    const _apsAdditionalApps = []; // ignored if _enableAdditionalApplications is false
    const _enableAdditionalApplications = false; // update _apsAdditionalApps appropriately
    const _enableFileView = true;
    const _enableViewInNewTab = true;
    const _enableShowHiddenFiles = true;
    const _enableOpenWith = true;
    const _mockOpenwith = false;
    const _openWithURL = "https://matlab.mathworks.com/open/matlabdrive/v1";
    const _enableViewerService = true;
    const _viewerServiceURL = "https://viewer.mathworks.com";
    const _showPreviewBanner = false;
    const _enableCopy = true;
    const _sharingEnabled = true;
    const _personalInvitationsEnabled = true;
    const _editPermissionsEnabled = true;
    const _emailUrlPrefix = `https://drive${getEnv()}.mathworks.com/sharing`;
    const _throwTranslationErrorEnabled = false;
    const _enableInvitationPreview = true;
    const _enableDirectoryUpload = true;
    const _shouldShowUnshareOption = true;
    const _embeddedWidgetTesterEnabled = false;
    const _enableMove = true;
    const _enableQualaroo = true;
    const _enableAdobeAnalytics = true;
    const _enableCopyToDrive = true;
    const _maxUploadFileSize = 2147483648; // size in bytes
    const _enableInfoPanel = true;
    const _enableFolderDownload = true;
    const _inlineFileView = true;
    const _matrixEnvironment = "production";
    const _matrixHost = "https://www.mathworks.com";
    const _searchEnabled = false;
    // getters to be exposed
    const getLoginUrl = function() { return _loginUrl; };
    const getLogoutUrl = function() { return _logoutUrl; };
    const getGdsUrl = function() { return _gdsUrl; };
    const getHtml5PushState = function() { return _html5PushState; };
    const getDaoClassName = function() { return _daoClassName; };
    const getRouterHistoryBase = function() { return _routerHistoryBase; };
    const isDeleteEnabled = function() { return _deleteEnabled; };
    const useAps = function() { return _useAPS; };
    const getApsBrokerUrl = function() { return _apsBrokerUrl; };
    const getApsAuthUrl = function() { return _apsAuthUrl; };
    const getApsLoginUrl = function() { return _apsLoginUrl; };
    const getApsAuthProxyUrl = function() { return _apsAuthProxyUrl; };
    const getApsStorageTopic = function() { return _apsStorageTopic; };
    const getApsResourceTopic = function() { return _apsResourceTopic; };
    const getApsResourceAttributesTopic = function() { return _apsResourceAttributesTopic; };
    const getApsInvitationTopic = function() { return _apsInvitationTopic; };
    const getApsSupportedApps = function() { return _apsAdditionalApps; };
    const isFileViewEnabled = function() { return _enableFileView; };
    const getInlineFileView = function() {return _inlineFileView;};
    const isViewInNewTabEnabled = function() { return _enableViewInNewTab; };
    const isShowHiddenFilesEnabled = function() { return _enableShowHiddenFiles; };
    const isAdditionalApplicationsEnabled = function() { return _enableAdditionalApplications; };
    const isOpenWithEnabled = function() { return _enableOpenWith; };
    const getOpenWithUrl = function() { return _openWithURL; };
    const mockOpenwith = function() { return _mockOpenwith; };
    const isViewerServiceEnabled = function() { return _enableViewerService; };
    const getViewerServiceUrl = function() { return _viewerServiceURL; };
    const isPreviewBannerEnabled = function() { return _showPreviewBanner; };
    const isCopyEnabled = function() { return _enableCopy; };
    const isSharingEnabled = function() { return _sharingEnabled; };
    const isEditPermissionsEnabled = function() { return _editPermissionsEnabled; };
    const getEmailUrlPrefix = function() { return _emailUrlPrefix; };
    const isThrowTranslationErrorEnabled = function() { return _throwTranslationErrorEnabled; };
    const isPersonalInvitationsEnabled = function() { return _personalInvitationsEnabled; };
    const isInvitationPreviewEnabled = function() { return _enableInvitationPreview; };
    const isDirectoryUploadEnabled = function() { return _enableDirectoryUpload; };
    const shouldShowUnshareOption = function() { return _shouldShowUnshareOption; };
    const isEmbeddedWidgetTesterEnabled = function() { return _embeddedWidgetTesterEnabled; };
    const isMoveEnabled = function() { return _enableMove; };
    const isQualarooEnabled = function() { return _enableQualaroo; };
    const isAdobeAnalyticsEnabled = function() { return _enableAdobeAnalytics; };
    const isCopyToDriveEnabled = function() { return _enableCopyToDrive; };
    const getMaxFileUploadSize = function() { return _maxUploadFileSize; };
    const isInfoPanelEnabled = function() { return _enableInfoPanel; };
    const isFolderDownloadEnabled = function() { return _enableFolderDownload; };
    const getMatrixEnv = function() { return _matrixEnvironment; };
    const getMatrixHost = function() { return _matrixHost; };
    const isSearchEnabled = function() { return _searchEnabled; };
    const isMwaLoginUsed = function() { return _useMwaLogin; };
    // public API: These methods are referenced outside
    return {
      getLoginURL: getLoginUrl,
      getLogoutURL: getLogoutUrl,
      getGdsURL: getGdsUrl,
      useHtml5PushState: getHtml5PushState,
      getDAOClassName: getDaoClassName,
      getRouterBase: getRouterHistoryBase,
      isDeleteEnabled: isDeleteEnabled,
      isAPSEnabled: useAps,
      getApsBrokerURL: getApsBrokerUrl,
      getApsAuthURL: getApsAuthUrl,
      getApsLoginURL: getApsLoginUrl,
      getApsAuthProxyURL: getApsAuthProxyUrl,
      getApsStorageTopic: getApsStorageTopic,
      getApsResourceTopic: getApsResourceTopic,
      getApsResourceAttributesTopic: getApsResourceAttributesTopic,
      getApsInvitationTopic: getApsInvitationTopic,
      getApsSupportedApps: getApsSupportedApps,
      isAdditionalApplicationsEnabled: isAdditionalApplicationsEnabled,
      isFileViewEnabled: isFileViewEnabled,
      getInlineFileView: getInlineFileView,
      isViewInNewTabEnabled: isViewInNewTabEnabled,
      isShowHiddenFilesEnabled: isShowHiddenFilesEnabled,
      isOpenWithEnabled: isOpenWithEnabled,
      getOpenWithURL: getOpenWithUrl,
      mockOpenwith: mockOpenwith,
      isViewerServiceEnabled: isViewerServiceEnabled,
      getViewerServiceURL: getViewerServiceUrl,
      isPreviewBannerEnabled: isPreviewBannerEnabled,
      isCopyEnabled: isCopyEnabled,
      isSharingEnabled: isSharingEnabled,
      isEditPermissionsEnabled: isEditPermissionsEnabled,
      getEmailUrlPrefix: getEmailUrlPrefix,
      isThrowTranslationErrorEnabled: isThrowTranslationErrorEnabled,
      isPersonalInvitationsEnabled: isPersonalInvitationsEnabled,
      isInvitationPreviewEnabled: isInvitationPreviewEnabled,
      isDirectoryUploadEnabled: isDirectoryUploadEnabled,
      shouldShowUnshareOption: shouldShowUnshareOption,
      isEmbeddedWidgetTesterEnabled: isEmbeddedWidgetTesterEnabled,
      isMoveEnabled: isMoveEnabled,
      isQualarooEnabled: isQualarooEnabled,
      isAdobeAnalyticsEnabled: isAdobeAnalyticsEnabled,
      isCopyToDriveEnabled: isCopyToDriveEnabled,
      getMaxFileUploadSize: getMaxFileUploadSize,
      isInfoPanelEnabled: isInfoPanelEnabled,
      isFolderDownloadEnabled: isFolderDownloadEnabled,
      getMatrixEnv: getMatrixEnv,
      getMatrixHost: getMatrixHost,
      isSearchEnabled: isSearchEnabled,
      isMwaLoginUsed: isMwaLoginUsed
    };
  })();
  return GCONFIG;
}); // require
